  .side-bar{
      border-radius: 11px; 
      padding: 20px 20px 20px 20px; 
      box-shadow: 0px 0px 2px #0000001a;
      background-color: #FFFFFF;
    }
  .side-bar a{
    text-decoration:none;
  }
  .side-bar li{
    list-style: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 590;
    color: #AAAAAA;
  }
  .side-bar li:hover{
    background-color: #000;
    color: #fff;
  }
  .side-bar img{
    margin-right: 30px;
    padding-left: 10px;
    width: 27px;
  }
  .side-bar li:hover img {
    filter: brightness(0) invert(1) !important;
  }
  .footer{
    background-color: #e5e5e7 !important;
    text-decoration: none   !important;
    color: #000 !important;
  }

  .active li{
    list-style: none;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 2px #0000001a;
    font-size: 14px;
    font-weight: 590;
    color: #fff;
    background-color: #000;
  }
  .active img{
    filter: brightness(0) invert(1) !important;
  }
  .footer img{
    filter: brightness(1)  !important;
  }
  