body {
  font-family: "Montserrat", sans-serif;
  background-color: #f7f7f9;
  overflow-x: hidden;
  white-space: nowrap;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "Montserrat", sans-serif;
}

input[type="text"] {
  font-size: 14px;
  font-weight: 500;
}

input[type="number"] {
  font-size: 14px;
  font-weight: 500;
}

input[type="password"] {
  font-size: 14px;
  font-weight: 500;
}

input[type="email"] {
  font-size: 14px;
  font-weight: 500;
}

input[type="date"] {
  font-size: 14px;
  font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


ul {
  list-style: none;
}

li {
  text-decoration: none !important;
}


/* layout frame start */
.part-card {
  padding-left: 30px;
  padding-right: 30px;
}

.sidebar-part {
  width: 19%;
  margin-top: 20px;
  margin-right: 30px;
  float: left;
}

.side-content-part {
  width: 100%;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* layout frame End */

.new-select {
  position: absolute;
  left: 30%;
}

/* all page card head */
.card-item {
  background-color: #f7f7f9;
  width: 100% !important;
  margin-left: 0px;
  display: flex;
  /* justify-items: center; */
  flex-direction: row;
  padding: 5px;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}

.ride-item h1 {
  width: 30% !important;
}

.rides-link {
  width: 50% !important;
  text-align: center !important;
}

.package-img {
  width: 10rem;
  height: 7rem;
  border-radius: 5px;
}

.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  width: 29rem;
}

.card-item-two {
  width: 50% !important;
}

.card-item a img {
  cursor: pointer;
}

.card-item p {
  cursor: pointer;
  display: inline-block;
}

.form-inputs {
  width: 200px;
  height: 36px;
  padding-left: 35px !important;
  margin-right: 14px;
  margin-top: 12px;
  margin-left: -17px;
  padding: 5px;
  box-shadow: 0px 0px 2px #0000001a;
  border: none;
  font-size: 13px !important;
}

.search-image img {
  position: relative;
  width: 17px;
  top: 17px;
  left: 10px;
}

.tile-img {
  width: 35px;
  margin-right: 20px;
}

.back-tile {
  width: 30px;
  box-shadow: 0px 0px 2px #0000001a;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 2px;
  padding: 5px;
  margin-right: 20px;
  margin-left: 10px;
}

.card-item h1 {
  font-size: 16px;
  font-weight: 600;
  padding: 6px;
  margin-top: 8px;
  width: 100%;
}

.img-page {
  cursor: pointer;
  width: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.img-filter {
  cursor: pointer;
  width: 38px;
  margin: 10px;
}

.active-page {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}



.main-card {
  border-radius: 10px !important;
  margin-bottom: 50px;
  /* box-shadow: 0px 1px 20px #00000040; */
  box-shadow: 0px 0px 2px #0000001a;
}

.fare-card {
  border-radius: 5px !important;
  margin-right: 30px;
  box-shadow: 0px 1px 8px #00000040;
  padding: 20px;
  width: 100%;
  max-width: 700px;
  white-space: pre-wrap;
}

.card-split {
  margin-top: 20px;
  /*padding: 30px;
  padding-bottom: 0px;
  padding-top: 0px; */
  padding-right: 30px;
  padding-left: 30px;
}

/* all page card  head*/




/* all page card & hover card */

.black {
  color: #aaaaaa !important;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 10px;
  margin-bottom: 25px !important;
  cursor: pointer;
}

.black:hover {
  background-color: #E0FBFC;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: #000 !important;
  transition-duration: 0.4s;
}

.head-card .sub-icon {
  width: 1.4rem;
  margin: 5px 6px;
}

.head-card .vehicle-icon {
  width: 1.3rem;
  margin: 7px 6px 5px;
  /* border-radius: 10px; */
  background: transparent;
}

/* .black:hover .head-card .sub-icon,
.black:hover .head-card .type-icon {
  filter: brightness(0) invert(1);
} */


.card-edit-icon {
  position: absolute;
  top: 0.4rem;
  right: 0.7rem;
  font-size: 0.7rem;
}

.head-card .status-icon {
  width: 2rem;
  margin: 5px 6px;
}

.head-card .type-icon {
  width: 1.3rem;
  margin: 5px 3px;
}



.head-card .on-duty-icon {
  width: 1.1rem;
  border-radius: 10%;
  position: absolute;
  right: 2rem;
  top: 0.68rem;
}


.contact-card .gender-icon {
  margin: -2px -2px 0 0;
  width: 1.8rem;
}

.head-card p {
  padding: 15px;
  padding-bottom: 0px;
  float: left;
  font-size: 10.5px;
  font-weight: 590;
}

.head-card i {
  margin: 10px;
  border-radius: 2px;
  padding-bottom: 0px;
  padding: 5px;
  float: right;
  font-size: 11px;
}

.head-card i:hover {
  background-color: #aaaaaa;
  color: #fff;
}

.black:hover .body-card h5 {
  color: #000 !important;
  font-weight: 600;
}

.black:hover .icon-part a {
  color: #000 !important;
  font-weight: 600;
}

.body-card {
  padding-left: 15px;
  padding-top: 5px;
  line-height: 5px;
}

.body-card img {
  float: left;
  width: 40px;
  height: 40px;
  /* border:1px solid red; */
  margin-right: 10px;
}

.body-cards img {
  float: left;
  width: 60px;
  height: 60px;
  /* border:1px solid red; */
  margin-right: 10px;
}

.body-card h5 {
  font-family: "Montserrat";
  font-weight: 600;
  color: #000;
  white-space: normal;
  padding-right: 20px;
  --lh: 1.4rem;
  line-height: var(--lh);
  padding-top: 3px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.body-card p {
  font-size: 10.5px;
  font-weight: 600;
  font-family: "Montserrat";
  float: left;
}

.contact-card {
  padding-top: 15px;
  padding-left: 15px;
}

.contact-card img {
  width: 25px;

  padding-right: 10px;
}

.contact-card p {
  font-family: "Montserrat";
  font-size: 10.5px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.icon-part img {
  float: left;

}

.icon-part a {
  font-size: 10px;
  position: absolute;
  top: 73%;
  right: 10px;
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}


.foot-card {
  background-color: #f7f7f9;
  padding-left: 15px;
  height: 38px;
  color: #aaaaaa;
  border-radius: 0px 0px 10px 10px;
  padding-top: 10px;
}

.foot-cards {
  background-color: #f7f7f9;
  padding-left: 15px;
  height: 70px;
  color: #aaaaaa;
  border-radius: 0px 0px 10px 10px;
  padding-top: 10px;
}

.foot-card p {
  font-size: 11px;
  font-weight: 600;
}

.foot-card img {
  float: left;
  width: 12px;
  margin-top: 2px;
  margin-right: 10px;
}

.black:hover .foot-card {
  background-color: #303030;
  transition-duration: 0.4s;
  border-radius: 0px 0px 10px 10px;
}

.black-one {
  margin-top: 10px;
}

.btn-load {
  margin-bottom: 40px;
  background-color: #000;
  padding: 7px 25px;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
}

.btn-load img {
  width: 12px;
  margin-right: 10px;
}

.fa-pen {
  color: rgb(170, 170, 170);
  cursor: pointer;
}

.fa-pen:hover {
  background-color: rgb(170, 170, 170);
  color: #000 !important;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.fa-eye {
  color: rgb(170, 170, 170);
  cursor: pointer;
}

.blacks {
  background-color: #000;
}

.blacks .foot-card {
  background-color: #303030;
}

.blacks .body-card h5 {
  color: #000;
  font-weight: 500;
}

/* all page card & hover card */

/* all pages Table start */

.table-th th {
  font-size: 13px;
  font-weight: 600;
  padding: 20px !important;
}

.table-td .tool-tip {
  max-width: 500px !important;
  white-space: nowrap;

}

.table-td .review {
  padding: 20px !important;
  max-width: 150px !important;
  white-space: nowrap;
}

.table-td .revieww {
  white-space: nowrap;
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}


.table-td .answer {
  white-space: nowrap;
  max-width: 400px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* width: 200px;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.table-td td {
  font-size: 12px;
  font-weight: 550;
  padding: 20px !important;
  color: #aaaaaa;
}

tr i {
  color: #aaaaaa !important;
}

tbody tr:hover {
  background-color: rgb(0, 0, 0);
}

tbody tr:hover i {
  color: #aaaaaa;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: 0.3px solid #aaaaaa !important;
}

.table-td td i {
  color: #000;
}

/* scrollbar start*/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
  border-radius: 10px;
}

/* scrollbar End*/

/* all pages Table End */












/* Login start */

.login-back {
  background-image: url(./assets/images/zuno.png);
  background-size: cover;
  height: 100vh;
}

.login-card {
  background-color: #fff;
  margin-top: 100px;
  padding: 25px;
  border-radius: 5px;
}

.login-card h6 {
  font-size: 12px;
  float: right;
}

.close {
  margin-top: -22px;
  position: absolute;
  font-size: 11px;
  left: 58%;
}

.open {
  margin-top: -22px;
  position: absolute;
  font-size: 11px;
  left: 58%;
}

.welcome {
  font-size: 13px;
  font-weight: 500;
}

.login-button {
  background-color: #FEBD2D !important;
  font-size: 13px;
  margin-top: 20px;
  padding: 0.55em 2em !important;
}

.login-card .form-label {
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

.foot-policy {
  margin-top: 40px;
  font-size: 11px;
  color: #000;
}

.foot-policy a {
  color: #FEBD2D;
  text-decoration: underline;
}

.privacy {
  margin-top: 20px;
}

/* .login-logo {
  animation-name: logo;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}


@keyframes logo {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
} */

/* Login End */
















/* sub Card Header */
.arrow {
  width: 20% !important;
}

.new-link {
  width: 27%;
}

.arrow i {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff !important;
  padding: 10px;
  box-shadow: 0px 0px 2px #0000001a;
  border-radius: 2px;
  font-size: 13px;
}

.arrow a {
  color: #000;
  /* text-decoration: none; */
  text-decoration: underline;
}


.new-head-link {
  margin-left: 10px !important;
}

.head-link {
  margin: 0 30px 0 -30px !important;
  overflow-x: auto;
}

.head-link a {
  margin: 5.2px;
  padding-bottom: 7px;
  position: relative;
  top: 15px;
  font-size: 12.5px;
  text-decoration: none;
  color: #aaaaaa;
  font-weight: 595 !important;
}

.head-link .active {
  color: #FEBD2D;
  border-bottom: 5px solid #FEBD2D;
}

.grey-btn {
  font-size: 13px;
  margin: 15px;
  background-color: #EEEEEE;
  border: none;
  color: #000;
  padding: 8px 15px;
  border-radius: 5px;
  margin-left: 0px;
}

.update {
  width: 100%;
  margin-top: 20px;
  /* margin: 0 20px 0 15px; */
}

.no_bar a {
  color: #aaaaaa;
}

.grey-btn:hover {
  background-color: #000;
  color: #fff;
}

.grey-color {
  font-size: 12px;
  margin-top: 20px;
  color: #000;
  padding: 8px 33px;
  border-radius: 3px;
  background-color: #EEEEEE;
  border: none;
}

.grey-color:hover {
  background-color: #000;
  color: #fff;
}

.green-btn {
  font-size: 13px;
  margin: 15px;
  background-color: #26D8A7;
  border: none;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-left: 0px;
}

.red-btn {
  font-size: 13px;
  margin: 15px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #FEBD2D;
  border: none;
}

.new-red {
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 5px;
}

.red-btn:hover {
  background-color: #000;
  color: #fff;
}

.red-color {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 20px;
  color: #fff;
  padding: 8px 33px;
  border-radius: 3px;
  background-color: #FEBD2D;
  border: none;
}

.red-color:hover {
  background-color: #000;
  color: #fff;
}

.red-btn img {
  margin-right: 10px;
  width: 1.1rem !important;
  padding-bottom: 2px;
}


/* sub Card Header  end*/

/* upload card start*/

.upload-btn {
  background-color: #fff;
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #000;
  padding: 5px 25px;
  border: none;
  font-size: 12px;
}

.upload-page {
  background-color: #f7f7f9;
  border-radius: 10px;
  padding: 10px;
  z-index: -40 !important;
  margin-top: 20px;
}

.avatar-card {
  padding-left: 25px;
  padding-right: 15px;
  /* padding-bottom: 20px; */
}

.avatar-card h1 {
  font-size: 16px;
  padding-top: 10px;
  font-weight: 590;
  margin-top: 25px;
}

.review-info {
  width: 100%;
  max-width: 700px;
  white-space: pre-wrap;
}

.review-info h3 {
  font-size: 16px;
  font-weight: 450;
}

.fare-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


/* .rides-info .review-info {
  width: 100%;
  max-width: 700px;
  white-space: pre-wrap;
} */

.rides-info .ride-head p {
  width: 100%;
  max-width: 700px;
  white-space: pre-wrap;
}

.rides-info hr {
  margin: 0px 16px 25px 20px;
}

.rides-info .col-md-10 {
  padding-left: 30px !important;
}

.rides-info .pickup {
  padding-left: 10px !important;
}


.rides-info .ant-divider-vertical {
  color: red;
  border-width: 10px;
}

.rides-info .col-md-8 {
  color: #AAAAAA;
}

.rides-info .ride-head {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.ride-head h3 {
  font-size: 16px;
  font-weight: 450;
}


.rides-info span {
  float: left;
}

.rides-info .title-img {
  margin-left: -30px;
  width: 7em;
}

.fare-card .fare-head {
  display: flex;
  align-items: flex-start;
}

.fare-head h3 {
  font-size: 16px;
  font-size: 450;
}

/* .rides-info .ride-head {
  font-size: 16px;
  font-weight: 450;
} */

/* .rides-link {
  margin-left: ;
} */

.rides-info .star-rating {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.txt-center {
  text-align: left;
}

.text-center {
  text-align: center;
}

/* .rides-info .col-md-3:first-child {
  padding-top: 20px !important;
}


.rides-info .col-md-3:last-child {
  padding-bottom: 20px !important;
} */

/* .rides-info .col-md-3,
.col-md-1,
.col-md-8 {
  font-size: 16px;
  padding: 8px 0px 10px;
  font-weight: 500;
} */

.border-class {
  border-right: 2px solid #0000000f;
  padding-right: 25px;
}

/* upload card end*/

/* sub form  start*/

.border-item {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
}

.border-item label {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 590;
}

.form-page {
  padding-top: 5px;
}

.form-page span {
  color: #FEBD2D !important;
  font-size: 13px;
}

.form-select {
  font-size: 13px;
  padding: 8px;
}

.head-document {
  background-color: #0000000f;
  /* border-radius: 5px; */
  border-radius: 6px 6px 0px 0px;
}

.head-document h3 {
  font-size: 13px;
  padding: 10px;
}

.upload-card-page {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, .125) !important;
  margin-top: 15px;
}

.page-document {
  margin-top: 30px;
}

.password-change h1 {
  margin-bottom: 25px;
}

.new-password {
  margin-top: -120px;
}

.new-passwords {
  margin-top: -80px;
}

.driver-status h1 {
  margin-bottom: 20px;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-select:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.Collapsible {
  border: 1px solid #000;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.register-status h1 {
  margin-bottom: 15px;
}

.accept {
  width: 100%;
  padding: 3px;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #0cab2c;
  color: #fff;
}

.reject-new {
  width: 100%;
  padding: 3px;
  border: none;
  color: #fff;
  border-radius: 6px;
  background-color: #FF4600;
  margin-top: 5px;
  margin-bottom: 10px;
}

.reject {
  width: 100%;
  padding: 3px;
  border: none;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.reject:hover {
  background-color: #000;
  color: white;
  transition-duration: 0.4s;
}

.driver-status img {
  margin-right: 10px;
}

.driver-status {
  line-height: 20px;
  cursor: pointer;
}

/* sub form  end*/

/* Password eye */

.log-eye {
  position: absolute;
  color: grey !important;
  left: 82%;
  margin-top: -25px;
  font-size: 13px;
}


.log-eyes {
  position: absolute;
  color: #dbdbdb !important;
  left: 82%;
  margin-top: -25px;
  font-size: 13px;
}


.log-eye-new {
  position: absolute;
  color: grey !important;
  left: 82%;
  margin-top: -25px;
  font-size: 13px;
}

.log-eyes-new {
  position: absolute;
  color: #dbdbdb !important;
  left: 63%;
  margin-top: -23px;
  font-size: 13px;
}


/* Password eye */










/*Benefit Referel*/
.part-expire {
  background-color: #F7F7F9;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 1px;
  font-size: 12px;
  line-height: 10px;

  margin-bottom: 5px;
}

.part-expire img {
  float: left;
  width: 15px;
  margin-right: 10px;
}

.part-expire p {
  line-height: 15px;
}

.part-expire i {
  float: right;
  position: relative;
  top: -25px;
  right: 20px;
}

.import {
  background-color: #fff;
  color: #AAAAAA;
  font-weight: 590;
  box-shadow: 0px 0px 2px #0000001a;
  border-radius: 2px;
  padding: 9px 15px;
  font-size: 11px;
  margin-right: 10px;
  margin-top: 17px;
  border: none;
}

.import img {
  width: 10px;
  margin-right: 10px;

}

.modal-title {
  font-size: 15px;
}

.modal-head button {
  font-size: 13px;

}


/*Benefit Referel*/















/* insuarnce info*/

.solo {
  float: left;
  margin-right: 10px;
  margin-top: 3px;
}

.alert p {
  font-size: 13px;
}

.alert {
  border: 1px solid #70707080;
  border-radius: 5px;
  margin-top: 20px;
}

.renew {
  background-color: #fff;
  color: #AAAAAA;
  font-weight: 590;
  box-shadow: 0px 0px 2px #0000001a;
  border-radius: 2px;
  padding: 9px 45px;
  font-size: 11px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
}


/* insuarnce info*/




.btn-red {
  font-size: 13px;
  position: relative;
  top: 14px;
  left: -30px;
  color: #fff;
  padding: 7.5px 15px;
  border-radius: 5px;
  background-color: #FEBD2D;
  border: none;
}

.btn-red:hover {
  background-color: #000;
  color: #fff;
}

.btn-red img {
  width: 15px;
  margin-right: 10px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 10%;
}

.card_avatar {
  width: 45px;
  height: 45px;
  border-radius: 10%;
}

.spin-wrapper {
  display: block !important;
  padding: 220px 0px !important;
  text-align: center !important;
  font-size: 20px;
}

.spin-td {
  position: relative;
  left: 450px;
  padding-top: 150px;
  padding-bottom: 150px;
  font-size: 20px;
}

.spin-wrappe {
  margin-left: 200px;
  padding-top: 120px;
  font-size: 15px;
  padding-bottom: 130px;
}

.form-check-label {
  /* line-height: 10px; */
  font-size: 13px;
}

.popover-body {
  padding: 30px;
  /* padding-top: 50px; */
}

.popover-body h6 {
  font-size: 13.5px;
  font-weight: 600;
  margin-bottom: 20px;

}

.popover-body .new {
  margin-top: -10px !important;
}

.lab-name {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
}

.previous a {
  background-color: #000 !important;
  color: #fff;
}

.next a {
  background-color: #000 !important;
  color: #fff;
}

.ant-picker-input>input {

  font-size: 12px !important;
  padding: 3px;
}

.ant-picker-content tbody tr:hover {
  background-color: #fff !important;
}

#popover-contained {
  background-color: #fff !important;
  z-index: 99;
  font-size: 13.2px;
  font-weight: 590;
  box-shadow: 0 4px 8px 0 #0000001A, 0 6px 20px 0 #0000001A;
}

.fade {
  background: var(--unnamed-color-ffffff) 0 0 no-repeat padding-box !important;
  opacity: 1;
  backdrop-filter: blur(11px);
}

.popover .popover-arrow {
  display: none;
}

.filter-btn {
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 0px 2px #0000001a;
  background-color: #fff;
  border: none;
  color: #AAAAAA;
  padding: 8px 30px;
  border-radius: 5px;
  /* margin-left: 0px; */
  font-size: 13.2px;
  font-weight: 590;
}

.filter {
  margin: 20px 10px;
  width: 40%
}

.filter-btn img {
  width: 18px;
  margin-right: 5px;
}

.filter-btn:active {
  border-color: #FEBD2D;
  color: #FEBD2D;
}

.file-preview {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 20px 10px;
}

.info-view {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.info-view-img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.file-preview>li {
  display: flex;
  padding: 10px;
  background-color: #F7F7F9;
  border-radius: 4px;
  margin-bottom: 15px;
}

.file-preview>li .doc-img {
  width: 4rem;
  height: 2rem;
  margin: 5px 0;
}

.img-preview {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: #F7F7F9;
  margin: 10px;
  border-radius: 5px;
}

.img-preview img {
  /* margin: 5px 10px 5px 20px; */
  /* width: 60px; */
  margin-left: 20px;
  margin-bottom: 10px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 2px 2px #0000000f;
}

.eye-img i {
  background-color: #fff;
  padding: 9px;
  border-radius: 4px;
  box-shadow: 2px 2px #0000000f;
}

.new-img-img img {
  margin-left: 15px;
  margin-top: 7px;
}

.img-preview p {
  text-align: center;
  margin-top: 3%;
  min-width: 120px;
  text-overflow: ellipsis;
}

.img-card {
  margin-top: 5%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 40px;
}

/* input[type=file] {
  display: none;
} */


.sc-gKseQn path {
  z-index: 1;
  color: #FEBD2D;
  width: 10px !important;
}

.sc-gKseQn path {
  z-index: 1;
  width: 10px !important;
}

.user-point {
  padding-left: 50px;
  padding-right: 50px;
}

.form-page .form-label {
  font-size: 13.5px;
  font-weight: 500;
}


.required-danger {
  color: #FEBD2D;
  position: absolute;
  font-size: 12px !important;
  margin-top: 3px;
  font-weight: 500;
}

.status_image {
  width: 12px;
  margin: 0 8px 2px;
}

.events {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 5px 2px 15px;
}

.events h1 {
  font-size: 13.5px;
  margin-top: 10px;
  font-weight: 600;
}

.events p {
  font-size: 11.5px;
  font-weight: 600;
  color: #FEBD2D;
  text-decoration: underline;
  margin-top: 10px;
  margin-right: 20px;
  text-decoration-color: #FEBD2D;
}

.border_line {
  margin-top: 5px;
  border-bottom: 1px solid #aaaaaa;
}

.cross {
  position: relative;
  right: 5%;
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}


.link_text {
  width: 20%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-preview li a {
  margin: 18px 5px 0;
}

.login {
  left: 59%;
}

.active:active {
  outline: none;
  border: none;
}

.trips_data {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #aaaaaa;
}

.new-pagination {
  padding-bottom: 15px;
}

.tabl th {
  /* background-color: #000; */
  position: relative;
  top: -4px;
  left: 10px;
  font-size: 13px;
  max-width: 300px;
}

.trips_data h3 {
  padding-top: 16px;
  padding-left: 20px;
  font-size: 13px;
  font-weight: 600;
}

.trips_data p {
  padding-top: 16px;
  padding-right: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #FEBD2D;
  text-decoration: underline;
  text-decoration-color: #FEBD2D;
}

.table-classs {
  overflow-y: auto;
  overflow-x: auto;
}

.table-class .table tbody tr td {
  font-weight: 400;
  white-space: nowrap;
  min-width: 60px;
}

.notify_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify_data h3 {
  width: 70%;
  position: fixed;
  font-size: 14px;
  font-weight: 600;
  left: 30px;
  background-color: #fff;
  top: 25px;
  /* padding: 10px 10px 10px !important; */
}

.notify_data p {
  top: 22px;
  position: fixed;
  right: 30px;
  background-color: #fff;
  color: #FEBD2D !important;
}

.notify_msg {
  width: 100%;
  cursor: pointer;
}

.notify-item {
  width: 30px;
  height: 30px;
  background-color: #EBEBEB;
  color: #121212;
  border-radius: 50%;
  font-weight: 450;
  border: none;
}

.scroll-page {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.time-ago {
  margin-left: 40px;
  margin-top: -5px;
}


.notify_msg img {
  float: left;
  clear: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 2px 10px 0 0;
}

.redeem_body {
  padding: 10px 20px 0px 20px;
}

.align-body {
  display: flex;
  align-items: baseline;
}

.redeem_body h3 {
  margin: 14px 0 22px;
  font-weight: 600;
  font-size: 13px;
}

.modal-content {
  border-radius: 5px;
  border: 1px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
}

.notify_btn {
  margin-top: -0.5px;
  margin-left: 10px;
  width: 37px;
  border-radius: 50%;
}

.header_card {
  padding: 20px 20px 10px 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 2px #0000001a;
}

tbody {
  background-color: #fff;
}

.header_card .row {
  margin-top: 10px;
}

.header-cards p {
  color: #aaaaaa;
}

.header_card img {
  float: left;
  clear: left;
  margin-right: 15px;
}

.files-share input[type=file] {
  width: 120px;
  color: transparent;
}

.img-save {
  width: 20px;
}

.files-share {
  text-align: center;
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #F7F7F9;
}

.into {
  text-align: end;
}


.image-btn::-webkit-file-upload-button {
  visibility: hidden !important;
}

.image-btn::before {
  content: 'Upload Photo';
  display: inline-block;
  background: #fff;
  border-radius: 3px;
  color: #000;
  padding: 5px 8px;
  outline: none;
  font-weight: 500;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-size: 10pt;
}

.image-btn:hover::before {
  border-color: black;
}

.image-btn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}



.image-btnn::-webkit-file-upload-button {
  visibility: hidden !important;
}

.image-btnn::before {
  content: 'Upload File';
  display: inline-block;
  background: #fff;
  border-radius: 3px;
  color: #000;
  padding: 5px 8px;
  outline: none;
  font-weight: 500;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-size: 10pt;
}

.image-btnn:hover::before {
  border-color: black;
}

.image-btnn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.pagi_body {
  display: flex;
  justify-content: center;
  align-items: center;
}





.page-not-found {
  text-align: center;
  background-color: #E5F9FF;
  height: 110vh;
  width: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
}

.page-not-found img {
  margin-top: 80px;
}

.page-not-found h1 {
  font-weight: 900;
  font-size: 45px;
}

.page-not-found p {
  font-size: 18px;
  font-weight: 600;
}


.login-button {
  background-color: #59BDDE;
  padding: 6px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.select-drop {
  padding: 25px 25px 0px;
}

.delete-icon {
  width: 30px;
  margin-top: 30px;
  margin-right: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.ilhwiv {
  max-width: 0px !important;
  max-height: 0px !important;
  min-width: 0px !important;
}

.ant-tooltip-inner {
  max-width: 700px;
  white-space: pre-wrap;
  padding: 5px;
  font-size: 13px;
  font-family: inherit;
}

textarea {
  font-weight: 450 !important;
  font-size: 14px !important;
  color: #000 !important;
}

.img-upload {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  margin-top: 20px;
}

.example::-webkit-scrollbar {
  display: none;
}

.download-img {
  padding: 8px 10px;
  width: 32px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #0000001a;
}

.doc-img {
  margin: 5px 0;
  width: 2rem;
  height: 1rem;
}

/* ride category-package start */
img {
  vertical-align: middle;
  border-style: none;
  /* width: 20px; */
}

.logo-img {
  width: 130px !important;
}

.button:disabled,
button[disabled] {
  cursor: default;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

/* ride category-package end */