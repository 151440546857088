body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-cards {
  color: #cbc9c9;
  font-size: 14px;
  font-weight: 550;
  cursor: pointer;
}

.header-cards h2 {
  font-size: 25px;
  font-weight: 900;
  margin-left: 40px;
  margin-top: -6px;
}

.header-cards img {
  width: 25px;
}

.pagination {
  margin: 30 auto;
  margin-top: 15px;
  /* position: relative;
  left: 35%; */
}

.ilhwiv {
  background-color: #fff;
  border-radius: 10px;
  z-index: 9;
}

.fade {
  z-index: 999999999;
}

.page-link {
  color: #000;
}

.pagination .page-item {
  margin-left: 5px;
  margin-right: 5px;
}

.page-item.active .page-link {
  background-color: #FEBD2D !important;
  color: #fff;
  border: none;
}