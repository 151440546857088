.navbar{
padding-left: 10px;
padding-right: 30px;
padding-top: 20px;
}
.navbar-light .navbar-nav .nav-link a {
 font-weight: bold;
 color: #121212;
}
.form-input {
width:550px;
margin-left: 100px;
padding-left: 40px;
box-shadow: 0px 0px 2px #0000001A;
border: none;
}
.search-img img{
  position: absolute;
  width: 17px;
  margin-left: 110px;
  top:46%;
}

.icon-button {
  width: 50px;
  height: 50px;
  margin-left: 20px;
  color: #333333;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #0000001a;
}

.icon-button__badge {
  position: absolute;
  padding:5px;
  top:25px;
  background: red;
  color: #ffffff;
  border-radius: 70%;
  font-size: 8px;
}

@media only screen and (max-width: 600px) {
  .form-input, .icon-button__badge, .search-img img {
    display: none;
    }
 
}